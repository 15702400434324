<template>
<CRow>
  <CCol col="12" xl="12">
    <CCard>
      <CCardHeader>
        <CCol col="6" class="styleHeader">  {{ $t('message.accountingReports')}}</CCol>
      </CCardHeader>
      <CCardBody>
        <div class="row">
            <div class="col-md-6">
                <table class="table table-bordered">
                  <tr>
                    <th> {{$t('message.totalWithdrawnMoney')}}  </th>
                    <td>{{ accounting.total_withdrawn_money | number}}</td>
                  </tr>
                  <tr>
                    <th> {{$t('message.totalReceivedMoney')}} </th>
                    <td>{{ accounting.total_received_money | number}}</td>
                  </tr>
                  <tr>
                    <th> {{$t('message.profist')}}  </th>
                    <td>{{ accounting.profist}}</td>
                  </tr>

                </table>
            </div>
          </div>
      </CCardBody>
    </CCard>
  </CCol>
</CRow>
</template>

<script>
export default {
  name: 'ShowSupplier',
  data () {
    return {
      accounting: []
    }
  },
  created () {
    this.$http
      .get(`${this.$hostUrl}report/accounting`)
      .then((response) => {
        this.accounting = response.data.data
      })
  }
}
</script>
